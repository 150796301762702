import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Fab from '@material-ui/core/Fab';
import { Fullscreen, FullscreenExit, ZoomIn, ZoomOut } from '@material-ui/icons';
import './V2Slideshow.css';

export default forwardRef(function V2Slideshow(props, ref) {
  const {
    fullscreen,
    fullscreenAvailable,
    src,
    onToggleFullscreenSlideshow,
  } = props;

  const [error, setError] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const [disabled, setDisabled] = useState({
    pan: true,
    zoomIn: false,
    zoomOut: true,
  });
  const [slideClass, setSlideClass] = useState('slide');

  const scaleOptions = {
    min: 1,
    max: 10,
  };

  const transformRef = useRef();
  const mountedRef = useRef();

  useEffect(() => {
    mountedRef.current = true;
    return () => mountedRef.current = false;
  }, []);

  useEffect(() => {
    let slideClass = 'slide';
    if (zoomed) { slideClass += ' zoomed'; }
    if (disabled.pan) { slideClass += ' no-pan'; }
    setSlideClass(slideClass);
  }, [zoomed, disabled.pan]);

  useImperativeHandle(ref, () => ({
    resetZoom: () => zoom('Reset')
  }));

  const zoom = (direction) => {
    const slide = transformRef.current;

    if (direction === 'Reset') {
      slide.resetTransform();
    } else {
      slide[`zoom${direction}`]();
    }

    setTimeout(() => handleZoomStop(slide), 500);
  };

  const handleButtonDoubleClick = (e) => {
    e.stopPropagation();
  };

  const handleSlideshowDoubleClick = () => {
    zoom(zoomed ? 'Reset' : 'In');
  };

  const handleZoomStop = slide => {
    if (!mountedRef.current) { return; }

    const { scale } = slide.state;
    const zoomed = scale !== 1;

    setZoomed(zoomed);
    setDisabled({
      pan: !fullscreen && !zoomed,
      zoomIn: scale >= scaleOptions.max,
      zoomOut: scale <= scaleOptions.min,
    });
  };

  const handleImgError = () => setError(true);
  const handleImgLoad = () => setError(false);
  const handleZoomInClick = () => zoom('In');
  const handleZoomOutClick = () => zoom('Out');

  return (
    <div
      className={'V2Slideshow ' + (fullscreen ? `fullscreen ${fullscreen}` : '')}
      onDoubleClick={handleSlideshowDoubleClick}>
      <TransformWrapper
        minScale={scaleOptions.min}
        maxScale={scaleOptions.max}
        panning={{ disabled: disabled.pan }}
        onZoomStop={handleZoomStop}
        ref={transformRef}
      >
        <div className="controls">
          <div>
            {fullscreenAvailable && (
              <Fab
                color="primary"
                onClick={onToggleFullscreenSlideshow}
                onDoubleClick={handleButtonDoubleClick}
                aria-label="Toggle full-screen slide">
                {fullscreen ? <FullscreenExit /> : <Fullscreen />}
              </Fab>
            )}
          </div>
          <div>
            <Fab
              className={!zoomed ? 'hidden' : ''}
              color="primary"
              onClick={handleZoomOutClick}
              onDoubleClick={handleButtonDoubleClick}
              disabled={disabled.zoomOut}
              aria-label="Zoom out">
              <ZoomOut />
            </Fab>
            <Fab
              color="primary"
              onClick={handleZoomInClick}
              onDoubleClick={handleButtonDoubleClick}
              disabled={disabled.zoomIn}
              aria-label="Zoom in">
              <ZoomIn />
            </Fab>
          </div>
        </div>
        <TransformComponent>
          <div className={slideClass}>
            <img
              className={(error || !src) ? 'hidden' : ''}
              src={src}
              onLoad={handleImgLoad}
              onError={handleImgError}
              alt="Graphic associated with current audio/video content" />
            {error &&
              <div className="slide-error">
                An error occurred while loading the current section.<br />
                Refresh the page to try again.
              </div>
            }
          </div>
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
});
