import React, { useState } from 'react';
import './Highlights.css';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import ListSubheader from '@material-ui/core/ListSubheader';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 1100
  },
  drawer: {
    width: drawerWidth,
    flexGrow: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }
}));

export default function Highlights(props) {
  const classes = useStyles();
  const { highlights, deleteHighlight } = props;
  const [open, setOpen] = useState(false);

  const updateHash = highlight => {
    window.location.hash = `highlight-${highlight.id}`;
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };

  return (
    <div>
      <Fab className={classes.fab} size="small" color="secondary" aria-label="Toggle Highlights" onClick={toggleDrawer(true)}>
        <NoteAddIcon />
      </Fab>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <ListSubheader component="div" id="nested-list-subheader">
          Highlights
        </ListSubheader>
        <ul className="SidebarHighlights">
          { highlights &&
            highlights.map((highlight, index) => (
              <li
                key={index}
                className="sidebar__highlight"
                onClick={() => {
                  updateHash(highlight);
                }}
              >
                <div>
                  {highlight.content.text ? (
                    <blockquote className="HighlightQuote">
                      {`${highlight.content.text.slice(0, 90).trim()}…`}
                    </blockquote>
                  ) : null}
                  <p>{highlight.comment.text}</p>
                  {highlight.content.image
                    ? (
                        <div
                          className="highlight__image"
                          style={{ marginTop: "0.5rem" }}
                        >
                          <img src={highlight.content.image} alt={"Screenshot"} />
                        </div>
                      )
                    : null
                    }
                  <div>
                    <button onClick={() => deleteHighlight(highlight)}>
                      Delete
                    </button>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </Drawer>
    </div>
  );
}
