import React, { Component } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';
import ErrorBoundary from 'react-error-boundary';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import LogRocket from 'logrocket';
import Book from './components/Book/Book';
import BookSolutions from './components/BookSolutions/BookSolutions';
import CssBaseline from '@material-ui/core/CssBaseline';
import config from './config';

const theme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: blueGrey[50],
      paper: '#2D3047',
    },
    primary: {
      main: '#0577b9',
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null
    };
  }

  componentDidMount() {
    axios.get(config.api.DOMAIN + '/api/auth/authenticate', { withCredentials: true })
    .then(responseData => {
      if (responseData.data.current_user) {
        const { current_user } = responseData.data;

        this.setState({
          currentUser: current_user
        });

        if (config.env !== 'local') {
          LogRocket.identify(current_user.id, {
            name: `${current_user.firstname} ${current_user.lastname}`,
            email: current_user.email,
          });

          this.bootIntercom();
        }
      }
    })
    .catch(error => {
      console.error(error);
    });
  }

  bootIntercom() {
    const { currentUser } = this.state;
    if (!currentUser || !window.Intercom) { return; }

    window.Intercom('boot', {
      app_id: config.intercomAppId,
      fwkApplication: 'Viewer',
      email: currentUser.email,
      user_id: currentUser.id,
      user_hash: currentUser.intercom_hash,
      hide_default_launcher: true,
    });
  }

  errorHandler(error, _componentStack) {
    const slack = require('./slack');
    slack.sendSlackError(error.stack);
  };

  render() {
    return (
      <ErrorBoundary onError={this.errorHandler}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Switch>
              <Route path="/books/:book_id/solutions/:filename" render={(props) => <BookSolutions {...props} currentUser={this.state.currentUser} />} />
              <Route path="/books/:book_id/:chapter_id/:section_id?/:element_id?" render={(props) => <Book {...props} currentUser={this.state.currentUser} />} />
            </Switch>
          </Router>
        </ThemeProvider>
      </ErrorBoundary>
    );
  }
}

export default App;