import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
}));

export default function Controls(props) {
  const { nextSection, prevSection } = props;
  const classes = useStyles();
  let history = useHistory();


  function handleClick(url) {
    history.push(url);
  }

  return (
    <>
      { prevSection &&
      <Fab size="medium" color="primary" aria-label="Navigate: Previous" onClick={() => handleClick(prevSection)}>
        <ArrowBackIcon />
      </Fab> }
      <div className={classes.grow} />
      { nextSection &&
        <Fab size="medium" color="primary"  aria-label="Navigate: Next" className={classes.nextButton} onClick={() => handleClick(nextSection)}>
          <ArrowForwardIcon />
        </Fab> }
    </>
  );
}
