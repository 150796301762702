import { useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import config from '../../config';

export const ENGAGEMENT_DELAY_SECONDS = 15;
export const SUBMIT_SECTION_ENDPOINT = `${config.api.DOMAIN}/api/engagement/sections/submit`;

// Recursive search
const findSectionById = (toc, id) => (
  toc.find(t => t.element_id === id) ??
  toc.flatMap(t => (t.sections ? findSectionById(t.sections, id) : [])).find(Boolean)
);

export const generateItemIdForEngagementApi = (toc, itemId) => {
  if (!toc) { return itemId; }

  const section = findSectionById(toc, itemId);
  if (!section) { return itemId; }

  return `${section.ordinal} ${section.title}`;
}

export default function SectionEngagement(props) {
  const {
    bookId,
    itemId,
    toc,
  } = props;

  const engagementTimerRef = useRef(null);

  const submitSection = useCallback(() => {
    clearEngagementTimer();

    axios.post(
      SUBMIT_SECTION_ENDPOINT,
      { book_id: bookId, item_id: generateItemIdForEngagementApi(toc, itemId) },
      { withCredentials: true }
    );
  }, [bookId, itemId, toc]);

  const startEngagementTimer = useCallback(() => {
    // After {ENGAGEMENT_DELAY_SECONDS}, submit section for Catapult Student Engagement API
    engagementTimerRef.current = setTimeout(submitSection, ENGAGEMENT_DELAY_SECONDS * 1000);
  }, [submitSection]);

  const clearEngagementTimer = () => {
    if (!engagementTimerRef.current) { return; }

    clearTimeout(engagementTimerRef.current);
    engagementTimerRef.current = null;
  };

  useEffect(() => {
    startEngagementTimer();

    // Clear timer on unmount
    return clearEngagementTimer;
  }, [itemId, startEngagementTimer]);

  return null;
}
