import React, { Component } from 'react';
import { Route } from "react-router-dom";
import UnauthorizedAccess from '../UnauthorizedAccess/UnauthorizedAccess';
import axios from 'axios';
import config from "../../config";
import Header from '../Header/Header';
import VideoPlayer from "../VideoPlayer/VideoPlayer"
import './BookSolutions.css';
import Cookies from 'js-cookie'

class BookSolutions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      videoJsOptions: {},
      hasPurchasedBookAccess: false
    };
  }

  verifyAccess() {
    if (this.props.currentUser){
      axios.get(config.api.DOMAIN + '/api/auth/authorize', {
        withCredentials: true,
        params: {
          'book_id': this.state.book_id
        }
      })
      .then(responseData => {
        this.setState({
          hasPurchasedBookAccess: responseData.data.has_access
        })
      })
      .catch(error => {
        console.error(error);
      });
    }
  }

  componentDidMount() {
    const currentComponent = this;
    const book_id = this.props.match.params.book_id;
    const filename = this.props.match.params.filename;
    this.setState({
      book_id: book_id,
      filename: filename
    });

    // get the toc for the book
    fetch(`/tocs/${book_id}.json`)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      return response.json();
    })
    .then(function(data) {
      currentComponent.setState({
        authors: data.authors,
        title: data.title,
      });

      document.title = `${data.title} | FlatWorld Viewer`;

    })
    .catch(function(error) {
      console.log('Looks like there was a problem: \n', error);
    });

    currentComponent.setVideo();
  }

  setVideo() {
    const solutions_book_id = 32281;
    const filepath = `/${solutions_book_id}/video_files/solutions/${this.props.match.params.filename}`

    axios.get(config.api.DOMAIN + '/api/content/videos', {
      withCredentials: true,
      params:
      {
        'videoPath': filepath
      }
    })
    .then(result => {

      Cookies.set('CloudFront-Policy', result.data.video_urls['cookies']['CloudFront-Policy'], { secure: true});
      Cookies.set('CloudFront-Signature', result.data.video_urls['cookies']['CloudFront-Signature'], { secure: true});
      Cookies.set('CloudFront-Key-Pair-Id', result.data.video_urls['cookies']['CloudFront-Key-Pair-Id'], { secure: true});
      this.setState({
        videoJsOptions: {
        autoPlay: true,
        controls: true,
        sources: [
          {
            src: result.data.video_urls['hls'],
            type: 'application/x-mpegURL',
            withCredentials: true,
          },
          {
            src: result.data.video_urls['dash'],
            type: 'application/dash+xml',
            withCredentials: true,
          },
          {
            src: result.data.video_urls['mp4'],
            type: 'video/mp4',
            withCredentials: true
          }
        ],
        poster: result.data.video_urls['thumbnail'],
        withCredentials: true
      }
      });
    })
    .catch(error => {
      console.error(error);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.verifyAccess();
    }
  }

  isAuthorized(){
    return (this.getRolePrivileges() || this.state.hasPurchasedBookAccess)
  }

  contentLoaded(){
    return this.state.videoJsOptions && this.state.videoJsOptions['sources'] !== undefined;
  }

  getRolePrivileges(){
    let userIsAdminOrEducator = false;
    const { currentUser } = this.props;

    if (currentUser) {
      const userRoles = currentUser.roles;
      if (userRoles && (userRoles.includes("administrator") || userRoles.includes("educator"))) {
        userIsAdminOrEducator = true;
      }
    }

    return userIsAdminOrEducator;
  }

  render() {
    const isAuthorized = this.isAuthorized();
    const contentLoaded = this.contentLoaded();
    const { currentUser } = this.props;
    const { title } = this.state;

    return (
      <div className="BookSolutions">

        <a href="#content" className="skip-main" tabIndex="-1">Skip to main content</a>

        <Header 
          currentUser={currentUser} 
          title={title}
          toggleDrawer={null} />

        {isAuthorized ? (

          <div id="content" className="Solution-content">
            {contentLoaded ? (
              <Route path={`${this.props.match.path}/watch`}
                render={() => <VideoPlayer videoJsOptions={this.state.videoJsOptions} />} />

            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <UnauthorizedAccess/>
        )}
      </div>
    );
  }
}

export default BookSolutions;
