import React, { Component } from 'react';
import './Book.css';
import BookContent from './BookContent';
import Header from '../Header/Header';
import Toc from '../Toc/Toc';
import axios from 'axios';
import config from "../../config";

class Book extends Component {
  constructor(props) {
    super(props)

    this.state = {
      drawerOpen: true,
      authors: '',
      title: '',
      toc: [],
      pdf_filename: '',
      prevSection: '',
      nextSection: '',
      currentChapterId: '',
      currentSectionId: '',
      currentSubSectionId: '',
      relativePageNumberHash: '',
      chapterPreviewable: false,
      hasPurchasedBookAccess: false
    }
  }

  verifyAccess(book_id) {
    if (this.props.currentUser){
      axios.get(config.api.DOMAIN + '/api/auth/authorize', {
        withCredentials: true,
        params: {
          'book_id': book_id
        }
      })
      .then(responseData => {
        this.setState({
          hasPurchasedBookAccess: responseData.data.has_access
        })
      })
      .catch(error => {
        console.error(error);
      });
    }
  }

  componentDidMount() {
    const currentComponent = this;
    const book_id = this.props.match.params.book_id;

    // get the toc for the book
    fetch(`/tocs/${book_id}.json`)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      return response.json();
    })
    .then(function(data) {
      currentComponent.setState({
        authors: data.authors,
        title: data.title,
        toc: data.toc
      });

      document.title = `${data.title} | FlatWorld Viewer`;

      currentComponent.setLocation();
    })
    .catch(function(error) {
      console.log('Looks like there was a problem: \n', error);
    });
  }

  componentDidUpdate(prevProps) {
    const { book_id, chapter_id, section_id, element_id } = this.props.match.params;

    if (prevProps.currentUser !== this.props.currentUser) {
      this.verifyAccess(book_id);
    }

    if (chapter_id !== prevProps.match.params.chapter_id || section_id !== prevProps.match.params.section_id || element_id !== prevProps.match.params.element_id) {
      this.setLocation();
    }
  }

  setLocation() {
    const { book_id, chapter_id, section_id, element_id } = this.props.match.params;
    const chapters = this.state.toc;
    const book_path = "/books/" + book_id;
    let currentChapterId, currentSectionId, currentSubSectionId, currentInternalId;

    if(chapters.length > 0) {
      var prevSection, nextSection, currentPdfFilename, relativePageNumberHash, currentChapterPreviewable;
      
      if (chapter_id && chapter_id !== "read") {
        //scroll to location on toc
        window.location.hash = "#" + chapter_id;

        // get the chapter we are on
        const chapter = chapters.find(obj => {
          return obj.element_id === this.props.match.params.chapter_id
        });

        currentPdfFilename = chapter.pdf_filename;
        currentChapterPreviewable = chapter.previewable;

        currentChapterId = chapter.element_id;
        prevSection = chapter.previous_element != null ? book_path + chapter.previous_element : '';
        nextSection = chapter.next_element != null ? book_path + chapter.next_element : '';

        // Check if this is a section
        if (section_id && section_id !== "read") {
          // get the current section
          const section = chapter.sections.find(obj => {
            return obj.element_id === this.props.match.params.section_id
          });

          currentPdfFilename = section.pdf_filename;
          currentSectionId = section.element_id;
          prevSection = section.previous_element != null ? book_path + section.previous_element: '';
          nextSection = section.next_element != null ? book_path + section.next_element: '';

          if (element_id && element_id !== "read") {
            const subsection = section.sections.find(obj => {
              return obj.element_id === this.props.match.params.element_id
            });

            if (subsection.pdf_filename) {
              currentPdfFilename = subsection.pdf_filename;
              relativePageNumberHash = subsection.relative_page_number;
            }

            currentSubSectionId = subsection.element_id;
            currentInternalId = subsection.internal_id;
            prevSection = subsection.previous_element != null ? book_path + subsection.previous_element: '';
            nextSection = subsection.next_element != null ? book_path + subsection.next_element: '';
          }
        }
      } else {
        window.location = book_path + '/' + chapters[0].element_id + '/read'
      }

      this.setState({
        prevSection: prevSection,
        nextSection: nextSection,
        pdf_filename: currentPdfFilename,
        currentChapterId: currentChapterId,
        currentSectionId: currentSectionId,
        currentSubSectionId: currentSubSectionId,
        currentInternalId: currentInternalId,
        relativePageNumberHash: relativePageNumberHash,
        chapterPreviewable: currentChapterPreviewable
      });
    }
  }

  isAuthorized(){
    return (this.getRolePrivileges() || this.state.chapterPreviewable || this.state.hasPurchasedBookAccess)
  }

  getRolePrivileges(){
    let userIsAdminOrEducator = false;
    const { currentUser } = this.props;

    if (currentUser) {
      const userRoles = currentUser.roles;
      if (userRoles && (userRoles.includes("administrator") || userRoles.includes("educator"))) {
        userIsAdminOrEducator = true;
      }
    }

    return userIsAdminOrEducator;
  }

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen })
  };

  render() {
    const isAuthorized = this.isAuthorized();
    const { currentUser } = this.props;
    const { book_id } = this.props.match.params;
    const { 
      currentChapterId, 
      currentSectionId, 
      currentSubSectionId,
      currentInternalId,
      title, 
      toc, 
      pdf_filename,
      prevSection,
      nextSection,
      relativePageNumberHash,
      drawerOpen } = this.state;

    return (
      <div className="Book">

        <a href="#content" className="skip-main" tabIndex="-1">Skip to main content</a>

        <Header 
          currentUser={currentUser}
          drawerOpen={drawerOpen}
          title={title}
          toggleDrawer={this.toggleDrawer} />
        
        <Toc
          book_id={book_id}
          toc={toc}
          currentChapterId={currentChapterId}
          currentSectionId={currentSectionId}
          currentSubSectionId={currentSubSectionId}
          drawerOpen={drawerOpen}
          toggleDrawer={this.toggleDrawer} />

        <BookContent
          currentUser={currentUser}
          isAuthorized={isAuthorized}
          toc={toc}
          book_id={book_id}
          currentChapterId={currentChapterId}
          currentSectionId={currentSectionId}
          drawerOpen={drawerOpen}
          pdf_filename={pdf_filename}
          relativePageNumberHash={relativePageNumberHash}
          currentInternalId={currentInternalId}
          prevSection={prevSection}
          nextSection={nextSection}
          path={this.props.match.path}
          element_id={this.props.match.params.element_id} />
      </div>
    );
  }
}

export default Book;
