import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Chapter from './Chapter';
import SubSection from './SubSection';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const drawerWidth = 350;
const mobileDrawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  logo: {
    marginRight: 'auto',
    height: 40
  },
  drawer: {
    flexGrow: 0,
    width: mobileDrawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: mobileDrawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  list: {
    width: '100%',
    maxWidth: mobileDrawerWidth,
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 32,
    [theme.breakpoints.up('sm')]: {
      maxWidth: drawerWidth
    },
  },
}));


export default function Toc(props) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState();
  const { book_id, toc, currentChapterId, currentSectionId, currentSubSectionId, drawerOpen, toggleDrawer } = props;
  let chapter = null;
  let section = null;

  if (currentSectionId) {
    chapter = toc.find(item => item.element_id === currentChapterId);
    section = chapter.sections.find(item => item.element_id === currentSectionId);
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const drawer = (
    <>
      <div className={classes.drawerHeader}>
        <img src="/logo.png" alt="FlatWorld Logo" className={classes.logo} />
        <IconButton onClick={(e) => toggleDrawer(e)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      {currentSectionId ?
        <SubSection
          book_id={book_id}
          chapter_id={currentChapterId}
          section_id={section.element_id}
          section={section} />
        :
        <List
          className={classes.list}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div">
              Table of contents
            </ListSubheader>
          }>
          {toc.map(function (chapter, index) {
            return (
              <Chapter
                active={selectedIndex === index}
                index={index}
                handleListItemClick={handleListItemClick}
                key={index}
                book_id={book_id}
                chapter={chapter}
                currentChapterId={currentChapterId}
                currentSectionId={currentSectionId}
                currentSubSectionId={currentSubSectionId}
                expanded={currentChapterId === chapter.element_id} />
            )
          })}
        </List>
      }
    </>
  );

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="persistent"
      anchor="left"
      open={drawerOpen}
    >
      {drawer}
    </Drawer>
  );
}