let env, domain, intercomAppId, slackWebhook;

if (process.env.REACT_APP_STAGE === 'production') {
  env = 'production';
  domain = '//viewer-api.flatworldknowledge.com';
  intercomAppId = 'g3z7yeed';
  slackWebhook = 'https://hooks.slack.com/services/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';
} else if (process.env.REACT_APP_STAGE === 'staging') {
  env = 'staging';
  domain = '//viewer-api.flatworldknowledgestage.com';
  intercomAppId = 'qzug4plw';
  slackWebhook = 'https://hooks.slack.com/services/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';
} else {
  env = 'local';
  domain = '//viewer-api.flatworldknowledgelocal.com:3006';
}

const config = {
  api: {
    DOMAIN: domain,
    SLACK_WEBHOOK_URL: slackWebhook
  },
  env,
  intercomAppId,
};

export default config;
