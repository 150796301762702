import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Section from './Section';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import './Toc.css';

class Chapter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    }
  }

  componentDidMount = () => {
    if (this.props.expanded) {
      this.setState({ expanded: true });
    }
  }

  handleClick = (e, index) => {
    this.props.handleListItemClick(index);

    if (this.state.expanded) {
      this.setState({ expanded: false })
    } else {
      this.setState({ expanded: true })
    }
  }

  render() {
    const { active, index, book_id, chapter, currentSectionId } = this.props;
    const { expanded } = this.state;

    const ExpandButton = (sections, expanded) => {
      if (!sections || !sections.length) {
        return;
      }

      return (
        <>
          {expanded ? <ExpandLess/> : <ExpandMore/>}
        </>
      )
    }

    return (
      <>
        <ListItem
          className="Toc-list-item"
          button
          component={NavLink}
          to={`/books/${book_id}/${chapter.element_id}/read`}
          onClick={(e) => this.handleClick(e, index)}
          selected={active}>

          <ListItemText>
            {chapter.ordinal && <strong>{chapter.ordinal}.&nbsp;</strong>}
            {chapter.title}  
          </ListItemText>

          {ExpandButton(chapter.sections, expanded)}
          
        </ListItem>
        { chapter.sections && chapter.sections.length ?
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List component="div" dense disablePadding>
              { chapter.sections.map(function(section) {
                return (
                  <Section key={section.element_id} book_id={book_id} chapter_id={chapter.element_id} section={section} currentSectionId={currentSectionId}/>
                )
              })}
            </List> 
          </Collapse> : null }
      </>
    );
  }
}

export default Chapter;
