import config from './config';

var SLACK_WEBHOOK_URL = config.api.SLACK_WEBHOOK_URL;
var slack = require('slack-notify')(SLACK_WEBHOOK_URL);

export function sendSlackError (error) {
  slack.send({
    text: `${error}`,
    icon_emoji: ':video_camera:',
    username: 'Viewer Client Error'
  })
};

// Stage errors post to tech-viewer-stage-log
// Prod errors post to tech-viewer-log
