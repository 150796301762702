import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactPlayer from 'react-player/vimeo';
import IconButton from '@material-ui/core/IconButton';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import V2Engagement from '../V2Engagement/V2Engagement'
import './V2Video.css';

export default forwardRef(function V2Video(props, ref) {
  const DEFAULT_WIDTH = '40%';
  const DEFAULT_PADDING_TOP = '75%';

  const {
    aspectRatio,
    bookId,
    currentUser,
    fullscreen,
    onError,
    onProgress,
    onReady,
    url,
  } = props;

  const [duration, setDuration] = useState(0);
  const [minimized, setMinimized] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [playing, setPlaying] = useState(false);
  const [error, setError] = useState(false);
  const [styles, setStyles] = useState({
    width: DEFAULT_WIDTH,
    paddingTop: DEFAULT_PADDING_TOP,
  });
  const [videoClass, setVideoClass] = useState('V2Video');

  const engagementRef = useRef();

  useEffect(() => {
    if (url === null) { return; }

    if (ReactPlayer.canPlay(url)) {
      const width = aspectRatio ? `${aspectRatio * 34}%` : DEFAULT_WIDTH;
      const paddingTop = aspectRatio ? `${(1 / aspectRatio) * 100}%` : DEFAULT_PADDING_TOP;
      setStyles({ width, paddingTop });
    } else {
      setError(true);
      onError();
    }
  }, [url, aspectRatio, onError]);

  useEffect(() => {
    let videoClass = 'V2Video';
    if (fullscreen) { videoClass += ` fullscreen ${fullscreen}`; }
    if (playing) { videoClass += ' playing'; }
    if (minimized) { videoClass += ' minimized'; }
    setVideoClass(videoClass);
  }, [fullscreen, playing, minimized]);

  useImperativeHandle(ref, () => ({
    resetMinimized: () => setMinimized(false),
  }));

  const handleBuffer = () => {
    setPlaying(false);
  };

  const handleBufferEnd = () => {
    setPlaying(true);
  };

  const handleDuration = (seconds) => {
    setDuration(seconds);
  }

  const handleEnded = () => {
    setPlaying(false);
  };

  const handleError = () => {
    setError(true);
    onError();
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handlePlaybackRateChange = (rate) => {
    setPlaybackRate(rate);
  }

  const handlePlay = () => {
    setPlaying(true);
    setError(false);
  };

  const handleProgress = (progressState) => {
    onProgress(progressState);
  };

  const handleReady = () => {
    setError(false);
    onReady();
  };

  const handleSeek = () => {
    engagementRef?.current?.onSeek();
  }

  const handleToggleMinimized = (e) => {
    // If triggered by click, lose focus to mimic Vimeo button style
    if (e.detail) { e.currentTarget.blur(); }
    setMinimized(!minimized);
  };

  return (
    <div
      className={videoClass}
      style={{ width: styles.width }}>
      {((fullscreen === 'slideshow')) && (
        <div className={'pip-overlay ' + (minimized ? 'minimized' : '')}>
          <IconButton
            color="primary"
            size="small"
            disableRipple={true}
            onClick={(e) => handleToggleMinimized(e)}
            aria-label="Toggle minimized video">
            {minimized ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </div>
      )}
      <div
        className={`player-wrapper ${error ? 'hidden' : ''}`}
        style={{ paddingTop: styles.paddingTop }}>
        <ReactPlayer
          className="ReactPlayer"
          width="100%"
          height="100%"
          controls={true}
          pip={true}
          playsinline={true}
          progressInterval={500}
          url={url}
          onBuffer={handleBuffer}
          onBufferEnd={handleBufferEnd}
          onDuration={handleDuration}
          onEnded={handleEnded}
          onError={handleError}
          onPause={handlePause}
          onPlay={handlePlay}
          onPlaybackRateChange={handlePlaybackRateChange}
          onProgress={handleProgress}
          onReady={handleReady}
          onSeek={handleSeek}
        />
      </div>
      {currentUser &&
        <V2Engagement
          bookId={bookId}
          duration={duration}
          playbackRate={playbackRate}
          playing={playing}
          url={url}
          ref={engagementRef}
        />
      }
      {error &&
        <div className="video-error">
          An error occurred while loading the video.<br />
          Refresh the page to try again.
        </div>
      }
    </div>
  );
});
