import React, { Component } from 'react';
import './Video.css';
import Controls from '../Controls/Controls'
import VideoEngagement from '../VideoEngagement/VideoEngagement';

class Video extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nextSection: '',
      prevSection: ''
    }

    this.setLocation = this.setLocation.bind(this);
  }

  setLocation() {
    const chapters = this.props.toc;
    const book_id = this.props.match.params.book_id;
    const chapter_id = this.props.match.params.chapter_id;
    const section_id = this.props.match.params.section_id;
    const element_id = this.props.match.params.element_id;

    if(chapters.length > 0) {
      var prevSection, nextSection;

      const chapter = chapters.find(obj => {
        return obj.element_id === this.props.match.params.chapter_id
      });

      const section = chapter.sections.find(obj => {
        return obj.element_id === this.props.match.params.section_id
      });

      for (var i = 0; i < section.sections.length; i++) {
        if(element_id === section.sections[i].element_id) {
          if (i - 1 >= 0) {
            prevSection = `/books/${book_id}/${chapter_id}/${section_id}/${section.sections[i-1].element_id}/watch`;
          }
          if (i + 1 < section.sections.length) {
            nextSection = `/books/${book_id}/${chapter_id}/${section_id}/${section.sections[i+1].element_id}/watch`;
          }
        }
      }

      this.setState({
        nextSection: nextSection,
        prevSection: prevSection
      });
    }
  }

  render() {
    return (
      <div className="Video">
        <iframe title="Video" allow="fullscreen" height="100%" width="100%" frameBorder="0" src={`https://content.thinkwell.com/content/${this.props.elementId}/view.html?layout=video&autoplay=false&bgcolor=transparent`}></iframe>
        <Controls nextSection={this.state.nextSection} prevSection={this.state.prevSection} />

        {this.props.currentUser && 
          <VideoEngagement
            bookId={this.props.bookId}
            duration={this.props.duration}
            elementId={this.props.elementId}
          />
        }
      </div>
    );
  }
}

export default Video;
