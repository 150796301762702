/* eslint import/no-webpack-loader-syntax: 0 */

import React, { Component } from 'react';
import config from "../../config";
import axios from 'axios';
import Spinner from "../Spinner/Spinner";
import Highlights from "../Highlights/Highlights";
import PDFWorker from "worker-loader!pdfjs-dist/lib/pdf.worker";
import SectionEngagement from '../SectionEngagement/SectionEngagement';
import {
  PdfLoader,
  PdfHighlighter,
  Tip,
  Highlight,
  Popup,
  AreaHighlight, setPdfWorker
} from "react-pdf-highlighter";
import './Pdf.css';

setPdfWorker(PDFWorker);

const HighlightPopup = ({ comment }) =>
  comment && comment.text ? (
    <div className="Highlight__popup">
      {comment.text}
    </div>
  ) : null;

class Pdf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pdf_url: '',
      highlights: []
    }

    this.getPdfUrl = this.getPdfUrl.bind(this);
    this.addHighlight = this.addHighlight.bind(this);
    this.getUserHighlights = this.getUserHighlights.bind(this);
  }

  componentDidMount() {
    if (this.props.pdf_filename && this.props.book_id) {
      this.getPdfUrl();
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.pdf_filename) && (this.props.pdf_filename !== prevProps.pdf_filename)) {
      this.getPdfUrl();
    }
  }

  getPdfUrl() {
    const currentComponent = this;
    let url = '';

    axios.get(config.api.DOMAIN + '/api/content/get_pdf_url', {
      withCredentials: true,
      params:
      {
        pdfFilename: this.props.pdf_filename,
        bookId: this.props.book_id
      }
    })
      .then(result => {
        if (currentComponent.props.relativePageNumberHash) {
          url = result.data.pdf_url + "#page=" + currentComponent.props.relativePageNumberHash;
        } else {
          url = result.data.pdf_url;
        }
        this.setState({
          pdf_url: url
        }, () => {
          this.getUserHighlights();
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  getUserHighlights() {
    axios.get(config.api.DOMAIN + '/api/content/highlights', {
      withCredentials: true,
      params: {
        book_id: this.props.book_id,
        chapter_id: this.props.chapterId,
        section_id: this.props.sectionId
      }
    })
      .then(result => {
        this.setState({
          highlights: JSON.parse(result.data.highlights)
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  addHighlight(highlight) {
    const { highlights } = this.state;

    axios(config.api.DOMAIN + '/api/content/highlights', {
      method: 'post',
      withCredentials: true,
      data: {
        highlight: {
          book_id: this.props.book_id,
          chapter_id: this.props.chapterId,
          section_id: this.props.sectionId,
          comment: highlight.comment,
          content: highlight.content,
          position: highlight.position
        }
      }
    })
      .then(result => {
        this.setState({
          highlights: highlights.concat(result.data.highlight)
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  deleteHighlight = (highlight) => {
    axios.delete(config.api.DOMAIN + '/api/content/highlights', {
      withCredentials: true,
      params: {
        id: highlight.id,
        book_id: this.props.book_id
      }
    })
      .then(result => {
        const filteredHighlights = this.state.highlights.filter(function (hl) { return hl.id !== result.data.id });

        this.setState({
          highlights: filteredHighlights
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  updateHighlight(highlightId, position, content) {
    this.setState({
      highlights: this.state.highlights.map(h => {
        return h.id === highlightId
          ? {
            ...h,
            position: { ...h.position, ...position },
            content: { ...h.content, ...content }
          }
          : h;
      })
    });
  }

  render() {
    const { pdf_url, highlights } = this.state;
    const { currentUser, book_id, chapterId, sectionId } = this.props;

    return (
      <div className="Pdf">
        <div style={{
          height: 'calc(100vh - 64px)',
          width: '100%',
          position: 'relative',
          backgroundColor: '#eceff1'
        }}
        >
          <PdfLoader url={pdf_url} beforeLoad={<Spinner />}>
            {pdfDocument => (
              <PdfHighlighter
                pdfDocument={pdfDocument}
                enableAreaSelection={event => event.altKey}
                // pdfScaleValue="page-width"
                scrollRef={scrollTo => {
                  this.scrollViewerTo = scrollTo;

                  // this.scrollToHighlightFromHash();
                }}
                onSelectionFinished={(
                  position,
                  content,
                  hideTipAndSelection,
                  transformSelection
                ) => (
                    <Tip
                      onOpen={transformSelection}
                      onConfirm={comment => {
                        this.addHighlight({ content, position, comment });

                        hideTipAndSelection();
                      }}
                    />
                  )}
                highlightTransform={(
                  highlight,
                  index,
                  setTip,
                  hideTip,
                  viewportToScaled,
                  screenshot,
                  isScrolledTo
                ) => {
                  const isTextHighlight = !Boolean(
                    highlight.content && highlight.content.image
                  );

                  const component = isTextHighlight ? (
                    <Highlight
                      isScrolledTo={isScrolledTo}
                      position={highlight.position}
                      comment={highlight.comment}
                    />
                  ) : (
                      <AreaHighlight
                        highlight={highlight}
                        onChange={boundingRect => {
                          this.updateHighlight(
                            highlight.id,
                            { boundingRect: viewportToScaled(boundingRect) },
                            { image: screenshot(boundingRect) }
                          );
                        }}
                      />
                    );

                  return (
                    <Popup
                      popupContent={<HighlightPopup {...highlight} />}
                      onMouseOver={popupContent =>
                        setTip(highlight, highlight => popupContent)
                      }
                      onMouseOut={hideTip}
                      key={index}
                      children={component}
                    />
                  );
                }}
                highlights={highlights}
              />
            )}
          </PdfLoader>
          {currentUser &&
            <Highlights
              highlights={highlights}
              deleteHighlight={this.deleteHighlight} />
          }
        </div>
        {currentUser &&
          <SectionEngagement
            bookId={book_id}
            itemId={sectionId ?? chapterId} />
        }
      </div>
    )
  }
}

export default Pdf;
