import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'calc(100vh - 64px)',
  },
  message: {
    padding: theme.spacing(1),
  },
}));

const UnauthorizedAccess = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Paper className={classes.message} elevation={3}>No preview available for this section.</Paper>
    </Box>
  );
}

export default UnauthorizedAccess;
