import React from 'react';
import { NavLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import ViewListIcon from '@material-ui/icons/ViewList';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import './Toc.css';

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    maxWidth: drawerWidth,
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 32
  },
}));

export default function SubSection(props) {
  const classes = useStyles();
  const { book_id, chapter_id, section_id, section } = props;
  const videos = section.sections.filter(section => section.formats.includes('video'));
  const exercises = section.sections.filter(section => section.formats.includes('pdf') && !section.formats.includes('video'));
  const [activeSubsection, setActiveSubsection] = React.useState(0);

  const getIcon = (subsection) => (
    subsection.formats.includes('video')
      ? <OndemandVideoIcon />
      : <ViewListIcon />
  );

  const getUrl = (subsection) => {
    const url = `/books/${book_id}/${chapter_id}/${section_id}/${subsection.element_id}/`;
    return `${url}${subsection.formats.includes('video') ? 'watch' : 'read'}`;
  }

  const handleClick = (event, activeSubsection) => {
    setActiveSubsection(activeSubsection);
  };

  return (
    <List className={classes.list} component="nav" disablePadding>
      <ListItem
        button
        component={NavLink}
        to={`/books/${book_id}/${chapter_id}/read`}
      >
        <ListItemIcon>
          <ArrowBackIcon />
        </ListItemIcon>
        <ListItemText primary="Back to chapter" />
      </ListItem>
      <Divider />
      <ListSubheader component="div">
        Text
      </ListSubheader>
      <ListItem 
        className="Toc-list-item"
        button
        component={NavLink}
        to={`/books/${book_id}/${chapter_id}/${section_id}/read`}
        onClick={(e) => handleClick(e, section_id)}
        selected={section_id === activeSubsection}
      >
        <ListItemIcon>
          <MenuBookIcon/>
        </ListItemIcon>
        <ListItemText>
          {section.ordinal && <strong>{section.ordinal}.&nbsp;</strong>}
          {section.title}  
        </ListItemText>
      </ListItem>
      <Divider />
      <ListSubheader component="div">
        Videos
      </ListSubheader>
      { videos.map(function(subsection, index) {
        return (
          <ListItem
            className="Toc-list-item"
            key={index}
            button
            component={NavLink}
            to={getUrl(subsection)}
            id={subsection.element_id}
            onClick={(e) => handleClick(e, subsection.element_id)}
            selected={subsection.element_id === activeSubsection}>
            <ListItemIcon>
              {getIcon(subsection)}
            </ListItemIcon>

            <ListItemText>
              {subsection.ordinal && <strong>{subsection.ordinal}.&nbsp;</strong>}
              {subsection.title}  
            </ListItemText>
          </ListItem>
        )
      })}
      <Divider />
      <ListSubheader component="div">
        Exercises
      </ListSubheader>
      { exercises.map(function(subsection, index) {
        return (
          <ListItem
            className="Toc-list-item"
            key={index}
            button
            component={NavLink}
            to={getUrl(subsection)}
            id={subsection.element_id}
            onClick={(e) => handleClick(e, subsection.element_id)}
            selected={subsection.element_id === activeSubsection}>
            <ListItemIcon>
              {getIcon(subsection)}
            </ListItemIcon>

            <ListItemText>
              {subsection.ordinal && <strong>{subsection.ordinal}.&nbsp;</strong>}
              {subsection.title}  
            </ListItemText>
          </ListItem>
        )
      })}
    </List>
  );
}
