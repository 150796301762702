import { useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import config from '../../config';

export const SUBMIT_VIDEO_ENDPOINT = `${config.api.DOMAIN}/api/engagement/videos/submit`;
export const WATCHED_THRESHOLD = .75; // (e.g. watched 75% of video)

export const submitDelayMs = (videoDurationSeconds) => {
  return (videoDurationSeconds * WATCHED_THRESHOLD) * 1000;
};

export default function VideoEngagement(props) {
  const {
    bookId,
    duration,
    elementId
  } = props;

  const engagementTimerRef = useRef(null);

  const submitVideo = useCallback(() => {
    clearEngagementTimer();

    axios.post(
      SUBMIT_VIDEO_ENDPOINT,
      { book_id: bookId, item_id: elementId },
      { withCredentials: true }
    );
  }, [bookId, elementId]);

  const startEngagementTimer = useCallback(() => {
    // After {submitDelayMs}, submit video for Catapult Student Engagement API
    engagementTimerRef.current = setTimeout(submitVideo, submitDelayMs(duration));
  }, [submitVideo, duration]);

  const clearEngagementTimer = () => {
    if (!engagementTimerRef.current) { return; }

    clearTimeout(engagementTimerRef.current);
    engagementTimerRef.current = null;
  };

  useEffect(() => {
    if (!duration) { return; }
    startEngagementTimer();

    // Clear timer on unmount
    return clearEngagementTimer;
  }, [elementId, duration, startEngagementTimer]);

  return null;
}
