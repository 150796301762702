import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import axios from 'axios';
import config from "../../config";

const drawerWidth = 350;
const mobileDrawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      background: 'transparent',
      boxShadow: 'none',
    }
  },
  appBarShift: {
    width: `calc(100% - ${mobileDrawerWidth}px)`,
    marginLeft: mobileDrawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      background: 'transparent',
      boxShadow: 'none',
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    }
  },
  icon: {
    [theme.breakpoints.up('sm')]: {
      color: '#263238',
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
}));


export default function Header(props) {
  const classes = useStyles();
  const {
    currentUser,
    drawerOpen,
    toggleDrawer } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGetHelp = () => {
    window.Intercom('show');
    handleClose();
  }

  const handleLogin = () => {
    axios.post(config.api.DOMAIN + '/api/auth/login')
      .then(responseData => {
        var redirect_url = responseData.data.sign_in_path;
        window.location.replace(redirect_url);
      })
    }

  const handleLogout = () => {
    axios.post(config.api.DOMAIN + '/api/auth/logout')
      .then(responseData => {
        var redirect_url = responseData.data.sign_out_path;
        window.location.replace(redirect_url);
      })
  }

  return (    
    <AppBar 
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: drawerOpen,
      })}>
      <Toolbar>
        <IconButton 
          edge="start" 
          className={clsx(classes.menuButton, drawerOpen && classes.hide)} 
          aria-label="menu" 
          onClick={(e) => toggleDrawer(e)}
        >
          <MenuIcon className={classes.icon}/>
        </IconButton>
        {currentUser ?
          <>
            <IconButton
              style={{marginLeft: 'auto'}}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              <AccountCircle className={classes.icon} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={!!anchorEl}
              onClose={handleClose}
            >
              <MenuItem onClick={handleGetHelp}>Get help</MenuItem>
              <Divider/>
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </>
          : <Button variant="contained" onClick={handleLogin} style={{marginLeft: 'auto'}}>Login</Button> }
        
      </Toolbar>
    </AppBar>
  );
}
