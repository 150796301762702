import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import axios from 'axios';
import config from '../../../config';

export const POLLING_INTERVAL = 30000;
export const WATCHED_THRESHOLD = .75; // (e.g. watched 75% of video)
export const SUBMIT_VIDEO_ENDPOINT = `${config.api.DOMAIN}/api/engagement/videos/submit`;

export default forwardRef(function V2Engagement(props, ref) {
  const {
    bookId,
    duration,
    playbackRate,
    playing,
    url,
  } = props;

  const [started, setStarted] = useState(null);
  const [ended, setEnded] = useState(null);
  const [lastPlaybackRate, setLastPlaybackRate] = useState(1);
  const [secondsPlayed, setSecondsPlayed] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const pollingIntervalIdRef = useRef(null);
  const pollingErrorRef = useRef(null);

  const resetState = () => {
    setStarted(null);
    setEnded(null);
    setLastPlaybackRate(1);
    setSecondsPlayed(0);
    setSubmitted(false);
  };

  const start = () => setStarted(Date.now());
  const end = () => setEnded(Date.now());

  const handleSeek = () => {
    if (submitted || !playing) { return; }

    end();
    start();
  };

  const stopPolling = useCallback(() => {
    clearInterval(pollingIntervalIdRef.current);
    pollingIntervalIdRef.current = null;
  }, [pollingIntervalIdRef]);

  const startPolling = useCallback(() => {
    if (pollingIntervalIdRef.current) { return; }

    // During playback, every {POLLING_INTERVAL}, update state / conditionally trigger submission
    const intervalId = setInterval(() => {
      try {
        pollingErrorRef.current = null;
        end();
        start();
      } catch (err) {
        console.error(err);
        pollingErrorRef.current = err;
      }
    }, POLLING_INTERVAL);

    pollingIntervalIdRef.current = intervalId;

    if (pollingErrorRef.current) { stopPolling(); }
  }, [pollingIntervalIdRef, stopPolling]);

  const secondsDelta = useCallback(() => {
    if (!started || !ended) { return 0; }

    return ((ended - started) / 1000) * lastPlaybackRate;
  }, [started, ended, lastPlaybackRate]);

  const shouldSubmit = useCallback(() => {
    if (submitted || !duration) { return false; }

    return duration && (secondsPlayed / duration) >= WATCHED_THRESHOLD;
  }, [duration, secondsPlayed, submitted]);

  const submitVideo = useCallback(() => {
    axios.post(
      SUBMIT_VIDEO_ENDPOINT,
      { book_id: bookId, item_id: url },
      { withCredentials: true }
    );
  }, [bookId, url]);

  useEffect(() => {
    resetState();
  }, [duration, url]);

  useEffect(() => {
    if (submitted) { return; }

    playing ? start() : end();
  }, [playing, submitted]);

  useEffect(() => {
    if (submitted || !playing || (playbackRate === lastPlaybackRate)) { return; }

    end();
  }, [playbackRate, lastPlaybackRate, playing, submitted]);

  useEffect(() => {
    if (submitted || started >= ended) { return; }

    setSecondsPlayed(secondsPlayed + secondsDelta());
    setEnded(null);

    if (playbackRate !== lastPlaybackRate) {
      setLastPlaybackRate(playbackRate);
      start();
    };
  }, [started, ended, playbackRate, lastPlaybackRate, secondsPlayed, secondsDelta, submitted]);

  useEffect(() => {
    if (!shouldSubmit()) { return; }

    submitVideo();
    setSubmitted(true);
  }, [shouldSubmit, submitVideo]);

  useEffect(() => {
    if (!started) { return; }
    if (submitted) { return stopPolling(); }

    startPolling();

    // Clear interval on unmount
    return stopPolling;
  }, [started, submitted, startPolling, stopPolling]);

  useEffect(() => {
    if (!ended) { return; }

    stopPolling();
  }, [ended, stopPolling]);

  useImperativeHandle(ref, () => ({
    onSeek: () => handleSeek(),
  }));

  return null;
});
