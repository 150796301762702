import React from 'react';
import { NavLink } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export default function Section(props) {
  const { book_id, chapter_id, section } = props;

  return (
    <React.Fragment>
      <ListItem
        button
        component={NavLink}
        style={{ paddingLeft: '2rem' }}
        to={`/books/${book_id}/${chapter_id}/${section.element_id}/read`}
        id={section.element_id}>

        <ListItemText>
          {section.ordinal && <strong>{section.ordinal}.&nbsp;</strong>}
          {section.title}
        </ListItemText>

      </ListItem>
    </React.Fragment>
  );
}
