import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Route } from "react-router-dom";
import Pdf from '../Pdf/Pdf';
import UnauthorizedAccess from '../UnauthorizedAccess/UnauthorizedAccess';
import V2Container from '../../V2/containers/V2Container';

const drawerWidth = 350;
const mobileDrawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  content: {
    marginLeft: -mobileDrawerWidth,
    paddingTop: 64,
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      marginLeft: -drawerWidth,
    }
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }
}));


export default function BookContent(props) {
  const classes = useStyles();
  const { 
    currentUser,
    isAuthorized,
    drawerOpen,
    pdf_filename,
    relativePageNumberHash,
    toc,
    book_id,
    currentInternalId,
    currentChapterId,
    currentSectionId,
    prevSection,
    nextSection,
    path,
    element_id
    } = props;

  return (    
    <main
      id="content"
      className={clsx(classes.content, {
        [classes.contentShift]: drawerOpen,
      })}
    >
      {isAuthorized ? (
        <>
          <Route 
            path={`${path}/read`}
            render={() => 
              <Pdf
                currentUser={currentUser}
                pdf_filename={pdf_filename}
                relativePageNumberHash={relativePageNumberHash}
                book_id={book_id}
                chapterId={currentChapterId}
                sectionId={currentSectionId}
                prevSection={prevSection}
                nextSection={nextSection} /> } />

          <Route 
            path={`${path}/watch`}
            render={() => (
              <V2Container
                currentUser={currentUser}
                toc={toc}
                bookId={book_id}
                elementId={element_id}
                internalId={currentInternalId} />)} />
        </>
      ) : (
        <UnauthorizedAccess />
      )}
    </main>
  );
}
