import React, { useEffect, useRef, useState } from 'react';
import fscreen from 'fscreen';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SectionEngagement from '../../components/SectionEngagement/SectionEngagement';
import Video from '../../components/Video/Video';
import V2Slideshow from '../components/V2Slideshow/V2Slideshow';
import V2Video from '../components/V2Video/V2Video';
import './V2Container.css';

const JSON_BASE_URL = 'https://viewer-video-presentations.flatworldknowledge.com';
const SLIDE_SRC_BASE_URL = 'https://images.flatworldknowledge.com/burger'

function generateSlideSrc(index, slides, internalId) {
  const src = slides[index] && slides[index].src;
  return src
    ? `${SLIDE_SRC_BASE_URL}/${internalId}/${src}`
    : '';
}

export default function V2Container(props) {
  const {
    currentUser,
    toc,
    bookId,
    elementId,
    internalId,
  } = props;

  const [slides, setSlides] = useState(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(null);
  const [currentSlideSrc, setCurrentSlideSrc] = useState(null);
  const [fullscreen, setFullscreen] = useState(null);
  const [fullscreenAvailable, setFullscreenAvailable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const [multiPart, setMultiPart] = useState(null);
  const [multiPartDuration, setMultiPartDuration] = useState(0);
  const [videoAspectRatio, setVideoAspectRatio] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const containerRef = useRef();
  const slideshowRef = useRef();
  const videoRef = useRef();
  const idRefs = useRef({});

  useEffect(() => {
    setFullscreenAvailable(fscreen.fullscreenEnabled && !/webkit/.test(fscreen.fullscreenPseudoClass));

    function handleFullscreenchange() {
      const el = fscreen.fullscreenElement;

      if (el) {
        const containerEl = containerRef.current;
        const fullscreenEl = el === containerEl ? 'slideshow' : 'video';
        setFullscreen(fullscreenEl);
      } else {
        setFullscreen(null);
      }

      slideshowRef.current.resetZoom();
      videoRef.current.resetMinimized();
    }

    fscreen.onfullscreenchange = handleFullscreenchange;
  }, []);

  useEffect(() => {
    if (idRefs.current.internalId === internalId) { return; }

    idRefs.current = {
      bookId,
      elementId,
      internalId,
    };

    setReady(false);
    setLoading(true);

    function initContent(region) {
      const video = region.find(r => r.name === 'vid');
      const updatedVideoUrl = video.src;
      const updatedVideoAspectRatio = +(+video.width / +video.height).toFixed(3);
      setVideoUrl(updatedVideoUrl);
      setVideoAspectRatio(updatedVideoAspectRatio);

      const updatedSlides = region.find(r => r.name === 'synch').img;
      const updatedMultiPart = false;
      setSlides(updatedSlides);
      setMultiPart(updatedMultiPart);
    }

    function initMultiPartSection(content) {
      setMultiPart(true);
      setMultiPartDuration(+content.videoSeconds || 0)
      setReady(true);
    };
  
    function fetchData() {
      fetch(`${JSON_BASE_URL}/${internalId}/${internalId}_1.json`)
        .then(res => res.json())
        .then(data => {
          const { content } = data;
          if (content.totalParts) {
            initMultiPartSection(content);
          } else {
            initContent(content.region);
          }
        })
        .catch(() => handleFetchDataError());
    }

    fetchData();
  }, [bookId, elementId, internalId]);

  useEffect(() => {
    if (!slides) { return; }

    function initSlides() {
      const currentSlideIndex = 0;
      const currentSlideSrc = generateSlideSrc(currentSlideIndex, slides, idRefs.current.internalId);

      setCurrentSlideIndex(currentSlideIndex);
      setCurrentSlideSrc(currentSlideSrc);
      setLoading(false);
    }

    initSlides();
  }, [slides]);

  const generateCurrentSlideIndex = (currentTime) => {
    // Find next slide index via current timestamp
    const nextIndex = slides.findIndex(s => +s.time >= currentTime);

    let updatedIndex;
    if (nextIndex === -1) {
      // If no nextIndex, set updatedIndex as last in array
      updatedIndex = slides.length - 1;
    } else {
      updatedIndex = (+slides[nextIndex].time === currentTime)
        ? nextIndex      // Exact timestamp
        : nextIndex - 1; // After timestamp, set as previous index
    }

    // Update state only when necessary
    if (updatedIndex !== currentSlideIndex) {
      const updatedSlideSrc = generateSlideSrc(updatedIndex, slides, internalId);
      setCurrentSlideIndex(updatedIndex);
      setCurrentSlideSrc(updatedSlideSrc);
    }
  };

  const handleFetchDataError = () => {
    setSlides([]);
    setCurrentSlideSrc('');
    setVideoUrl('');
    setMultiPart(false);
    setLoading(false);
  };

  const handleToggleFullscreenSlideshow = () => {
    if (!fullscreenAvailable) { return; }
    const containerEl = containerRef.current;

    if (!fullscreen) {
      if (fscreen.requestFullscreenFunction(containerEl)) { fscreen.requestFullscreen(containerEl); }
    } else {
      if (fscreen.fullscreenElement) { fscreen.exitFullscreen(); }
    }
  };

  const handleVideoError = () => {
    setReady(true);
  };

  const handleVideoProgress = (progressState) => {
    generateCurrentSlideIndex(progressState.playedSeconds);
  };

  const handleVideoReady = () => {
    setReady(true);
  };

  const renderContent = () => {
    if (multiPart) {
      return (
        <Video
          bookId={bookId}
          currentUser={currentUser}
          duration={multiPartDuration}
          elementId={elementId}
        />
      );
    }

    if (!loading) {
      return (
        <>
          <V2Video
            aspectRatio={videoAspectRatio}
            bookId={bookId}
            currentUser={currentUser}
            fullscreen={fullscreen}
            url={videoUrl}
            onError={handleVideoError}
            onProgress={handleVideoProgress}
            onReady={handleVideoReady}
            ref={videoRef}
          />
          <V2Slideshow
            fullscreen={fullscreen}
            fullscreenAvailable={fullscreenAvailable}
            src={currentSlideSrc}
            onToggleFullscreenSlideshow={handleToggleFullscreenSlideshow}
            ref={slideshowRef}
          />
        </>
      );
    }
  };

  return (
    <div
      className={'V2Container ' + (fullscreen ? 'fullscreen' : '')}
      ref={containerRef}
    >
      {renderContent()}
      <Backdrop
        className="Backdrop"
        open={!ready}
        transitionDuration={{ appear: 400, exit: 1000 }}>
        <CircularProgress color="primary" size="8vmax" />
      </Backdrop>
      {currentUser &&
        <SectionEngagement
          toc={toc}
          bookId={bookId}
          itemId={elementId} />
      }
    </div>
  );
}
